import React from 'react'
import InstagramFeed  from 'react-ig-feed'
import {ErrorBoundary} from 'react-error-boundary'
import 'react-ig-feed/dist/index.css'
import Home from '../Home/Home'


function Gallery() {
   
    return ( 
        <div className='Feed'>
        <ErrorBoundary
            FallbackComponent={Home}
            onReset={() => {
                // reset the state of your app so the error doesn't happen again
              }}
        >
        <InstagramFeed token='IGQVJYbElCOU1uTEVrRzNFVGUyRUlRcU44ejJnTVJsdjZArNGpSREJXdUswSFZAhZA2Y4dEpnQUlZAc1c2akU3ZAVBDb2pOczRQVTJ1ZA0FzTnRSS2RTdWxxamtmN1I5NnNqVWV3OEZAWWFdxYVJXeGwxUFpTaAZDZD' counter="18"/>
        </ErrorBoundary>
        </div>
    )
}

export default Gallery
