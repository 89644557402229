import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Home from '../Home/Home'
import Order from '../Order/Order'
import FAQ from '../FAQ/FAQ'
import Gallery from '../Gallery/Gallery'
import Pricing from '../Pricing/Pricing'
import Thankyou from '../Order/Thankyou'

function Main() {
    return (
        <main>
            <Switch>
                <Route exact path='/' component={Home}/>
                <Route path='/pricing' component={Pricing}/>
                <Route path='/order' component={Order}/>
                <Route path='/gallery' component={Gallery}/>
                <Route path='/FAQ' component={FAQ}/>
                <Route path='/thank-you/' component={Thankyou}/>
            </Switch>
        </main>
    )
}

export default Main
