import * as React from 'react'
import { Container, AppBar, Toolbar, List, ListItem, ListItemText, Hidden } from '@material-ui/core'
import {  NavLink } from 'react-router-dom'
import { makeStyles } from "@material-ui/core/styles";
import SideDrawer from '../SideDrawer/SideDrawer'
import logo from '../../assets/logo.png'


const useStyles = makeStyles({
  logo: {
    width: 120,
    height: 100,
    position: `absolute`,
    left:20,
    top:20,
  },
  navBar: {
    backgroundColor:`#F5FFFB`,
    height:125,
    borderBottom: 'none'
  },
  navbarDisplayFlex: {
    display: `flex`,
    justifyContent: `flex-end`,
  },
  navDisplayFlex: {
    display: `flex`,
    justifyContent: `space-between`,
  },
  linkText: {
    textDecoration: `none`,
    textTransform: `lowercase`,
    color: `black`,
  }
});

const Header = () => {
    const classes = useStyles();
    return (
        <AppBar position ='static' elevation={0}>
            <Toolbar className={classes.navBar}>
                <Container maxWidth='lg' className={classes.navbarDisplayFlex}>
                <NavLink to='/' key='Home'>
                <img src={logo} alt={'logo'} className={classes.logo}/>
                </NavLink>
                        <Hidden smDown>
                            <List component ='nav' className={classes.navDisplayFlex}>
                                <NavLink to='/' key='Home' className={classes.linkText}>
                                    <ListItem button>
                                        <ListItemText primary='Home' />
                                    </ListItem>
                                </NavLink>
                                <NavLink to='/pricing' key='Pricing' className={classes.linkText}>
                                    <ListItem button>
                                        <ListItemText primary='Pricing' />
                                    </ListItem>
                                </NavLink>
                                <NavLink to='/order' key='Order' className={classes.linkText}>
                                    <ListItem button>
                                        <ListItemText primary='Order' />
                                    </ListItem>
                                </NavLink>
                                <NavLink to='/gallery' key='Gallery' className={classes.linkText}>
                                    <ListItem button>
                                        <ListItemText primary='Gallery' />
                                    </ListItem> 
                                </NavLink>
                                <NavLink to='/FAQ' key='FAQ' className={classes.linkText}>
                                    <ListItem button>
                                        <ListItemText primary='FAQ' />
                                    </ListItem> 
                                </NavLink>
                            </List>
                        </Hidden>
                     <Hidden mdUp>
                        <SideDrawer />
                     </Hidden>
                </Container>
            </Toolbar>
        </AppBar>
    )
}

export default Header
