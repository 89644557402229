import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from '@material-ui/core';
import fei from '../../assets/fei.jpeg'

const useStyles = makeStyles({
    root: {
        fontFamily: 'Spartan, sans-serif',
        minHeight: '100vh',

    },
    title: {
        fontSize: '4rem', 
        fontFamily: 'Spartan, sans-serif',
        color: '#ffadad'
    },
    desc: {
        fontSize: '1.25rem',
        padding: '5px'
    },
    fei: {
        objectFit: 'cover',
        maxWidth:'100%',
        maxHeight:'100%',
    }
});

function AboutMe2() {
    const classes = useStyles();
    return (
        <div className='root'>
            <Grid container spacing={3}>
                <Grid item lg={2}></Grid>
                <Grid item xs={12} lg={3}>
                    <img src={fei} className={classes.fei} alt='fei'/>
                </Grid>
                <Grid item lg={1}></Grid> 
                <Grid item xs={12} lg={4}>
                    <Typography className={classes.title}> About </Typography>
                    <Typography className={classes.desc}> Hi I’m Fei! I’m a college student attending the University of California, Davis
                     and in the midst of the COVID-19 pandemic in search of a quarantine hobby, SweetzbyFei was born. 
                     I have always loved baking since I was little and this platform has given me the perfect creative outlet combined with my 
                     love for desserts. Each order is handcrafted with simple, fresh ingredients and I am always delighted to be a part of your 
                     special occasions...or casual cravings. Whether you’re a returning client or just so happen to stumble across my page, thank you for stopping by!   </Typography>

                </Grid>
             </Grid>
            
        </div>
    )
}

export default AboutMe2
