import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles({
    root: {
        width: '100%'
    },
    title: {
        fontFamily: 'Spartan, sans-serif',
        
    },
    heading: {
        fontFamily: 'Roboto',
        textAlign: 'left',
        

    },
    accordion:{
        backgroundColor: '#F5FFFB',
        textAlign: 'left'
       
    }
  
});

function FAQ() {
    const classes = useStyles();
    return (
        <div>
            <h3 className = {classes.title}>Frequently Asked Questions</h3>
        <Accordion className = {classes.accordion}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{color: '#ffadad'}} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography className={classes.heading}>Where are you located?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
            I am mainly located in El Sobrante, California but I am also able to take orders in Davis, California. 
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion className = {classes.accordion}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{color: '#ffadad'}}/>}
            aria-controls="panel2a-content"
            id="panel2a-header"
            >
            <Typography className={classes.heading}>How do I place an order?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
            Please visit the Order tab here on our website to submit an inquiry form. Shortly after submitting your request, I will reach out to you to discuss the details and confirm your order. 
            </Typography>
            </AccordionDetails>
      </Accordion>
      <Accordion className = {classes.accordion}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{color: '#ffadad'}} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography className={classes.heading}>How far in advance should I place my order?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
            The earlier the better! Please give me at least a three day notice in advance when placing your order. 
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion className = {classes.accordion}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{color: '#ffadad'}} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography className={classes.heading}>How much do you charge for delivery?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
            I calculate delivery costs based on your exact location. I can provide an exact price when discussing the details of your order. 
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion className = {classes.accordion}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{color: '#ffadad'}} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography className={classes.heading}>When is my payment due?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
            I require a 50% non refundable deposit to secure your order, but clients have the option to pay their total in full if they would like. The only methods of payment I accept at this time are Venmo or Zelle. 
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion className = {classes.accordion}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{color: '#ffadad'}} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography className={classes.heading}>What is your cancellation policy? </Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
            If you would like to cancel your order, I require a minimum of 48 hours notice prior to your order date. I will not allow cancellations after that to ensure that no product is wasted. If you would like to reschedule your order for another date, your deposit can be transferred.  
            </Typography>
            </AccordionDetails>
        </Accordion>
      
        </div>
    )
}

export default FAQ
