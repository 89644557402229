import Hero from '../Hero/Hero'
import Sweets from './Sweets';
import AboutMe2 from './AboutMe2';

function Home() {
  return (
    <div>
    <Hero />
    <Sweets/>
    <AboutMe2/>
    </div>
  );
}

export default Home;
