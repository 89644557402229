import React, { useState } from 'react'
import { TextField, Grid, Paper, Tabs, Tab, FormControl, Hidden, FormGroup, FormControlLabel, Checkbox, Button, InputAdornment, Typography } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';
import { useHistory } from "react-router-dom"
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import NumberFormat from 'react-number-format';
import HomeIcon from '@material-ui/icons/Home';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Event from "@material-ui/icons/Event";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const useStyles = makeStyles({
    formContainer: {
      backgroundColor: '#F5FFFB',
      fontFamily: 'Rubik'
    },

    groot: {
        paddingBottom: 25,
        backgroundColor: '#F5FFFB'
    },
    root: {
        marginBottom: 8,
        backgroundColor: '#F5FFFB'
    },

    textFieldOutline: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ffadad"
          },
    },

    input: {
        display: 'none'
    },
    indicator: {
        backgroundColor: '#ffadad'
    },
    typography: {
        margin: '8px'
    }
  
});

const materialTheme = createMuiTheme({
    palette: {
        primary: { 500: '#ffadad' },
      },
    overrides: {
      MuiTab:{
        textColorSecondary: {
            "&$selected": {
            'color': '#ffadad'
            }
        }   
      },
      MuiFormLabel:{
          root:{
              '&$focused': {
                  'color': '#ffadad !important'
              }
          },
          focused: {}
      },
      MuiInput:{
          underline:{
              '&:after': {
                  borderBottom: '2px solid #ffadad'
              }
          }
      },
      MuiButton:{
        textPrimary: {
            color: 'black'
        },
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: '#ffadad',
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          color: 'black'
        },
      },
      MuiPickersDay: {
        day: {
          color: '#ffadad',
        },
        daySelected: {
          backgroundColor: '#ffadad',
          '&:hover': {
            backgroundColor: '#ffadad',
          },
        },
        dayDisabled: {
          color: '#ffadad',
        },
        current: {
          color: '#ffadad'
        },
      },
      MuiPickersModal: {
        dialogAction: {
          color: '#ffadad'
        },
      },
    },
  });


function Order() { 
    const formRef = React.useRef();
    let history = useHistory()
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [name, setName] = useState('')
    const [number, setNumber] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [requests, setRequests] = useState('')
    const [file, setFile] = useState('');
    const [product, setProduct] = useState({
        strawberries: false,
        cookies: false,
        heart: false,
        cheesecake: false
      });
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const encode = (data) => {
        const formData = new FormData();
        for (const key of Object.keys(data)) {
          formData.append(key, data[key]);
        }
        return formData;
      }

    const handleChange = (event) => {
        setProduct({ ...product, [event.target.name]: event.target.checked });
    };

    function handleUpload(event) {
        setFile(event.target.files[0]);
      }

    const handleSubmit = e => {
        if (value === 1){
            handleClickOpen();
            return;
        }
        if (formRef.current.reportValidity() && value == 0) {
        const data = { "form-name": "order", file, name, number, email, address, requests}
        let fd = encode(data)
        fd.append('date', selectedDate.toString())
        fd.append('product', JSON.stringify(product))
        fetch("/", {
          method: "POST",
          // headers: { "Content-Type": "multipart/form-data" },
          body: fd
        })
          .then(() => history.push("/thank-you"))
          .catch(error => alert(error));
  
        e.preventDefault();
    }
    };
    
    const { strawberries, cookies, heart, cheesecake } = product;

    return (
        <form 
        className={classes.groot} 
        ref={formRef}
        name='order'
        id='order'
        method='post'
        data-netlify='true'
        data-netlify-honeypot='bot-field'
        action='/thank-you'
        onSubmit={handleSubmit}>
        <Dialog
        PaperProps={{
            style: {
                backgroundColor: '#F5FFFB',
                boxShadow: 'none',
            },
        }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
          <ThemeProvider theme={materialTheme}>
        <DialogTitle>{"Sorry, delivery is unavailable currently."}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Due to the increased order volume for Valentines Day, I can only fulfill pick up orders.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <ThemeProvider theme={materialTheme}>
          <Button onClick={handleClose} variant='contained' color='primary'>okay</Button>
          </ThemeProvider>
        </DialogActions>
        </ThemeProvider>
      </Dialog>
            <Paper square className={classes.root}>
            <ThemeProvider theme={materialTheme}>
                <Tabs
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    name='value'
                    variant="fullWidth"
                    classes={{ indicator: classes.indicator }}
                    indicatorColor="secondary"
                    textColor="secondary"
                    aria-label="icon label tabs example"
                >
                    <Tab icon={<HomeIcon />} label="PICKUP" />
                    <Tab icon={<LocalShippingIcon />} label="DELIVERY" />
                </Tabs>
                </ThemeProvider>
                </Paper>
            <Grid 
                container spacing={2} 
                className={classes.formContainer}   
                alignItems="center"
                justify="center"
            >
                <Grid item xs={12}>
                    <ThemeProvider theme={materialTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        required
                        label='Desired Date'
                        value={selectedDate}
                        InputProps ={{
                            endAdornment: (
                            <InputAdornment>
                            <Event style ={{color: "#ffadad",}} />
                            </InputAdornment>
                            )
                        }}
                        onChange={(newValue) => {
                            setSelectedDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    </MuiPickersUtilsProvider>
                    </ThemeProvider>
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset" >
                            <FormGroup name='product'>
                                <FormControlLabel
                                    control={<Checkbox style ={{color: "#ffadad",}} icon={<FavoriteBorderIcon/>} checkedIcon={<FavoriteIcon/>} checked={strawberries} onChange={handleChange} name="strawberries" />}
                                    label="Chocolate Covered Strawberries"
                                />
                                <FormControlLabel
                                    control={<Checkbox style ={{color: "#ffadad",}} icon={<FavoriteBorderIcon/>} checkedIcon={<FavoriteIcon/>} checked={heart} onChange={handleChange} name="heart" />}
                                    label="Breakable Heart"
                                />
                                <FormControlLabel
                                    control={<Checkbox style ={{color: "#ffadad",}} icon={<FavoriteBorderIcon/>} checkedIcon={<FavoriteIcon/>} checked={cheesecake} onChange={handleChange} name="cheesecake" />}
                                    label="Churro Cheesecake"
                                />
                                <FormControlLabel
                                    control={<Checkbox style ={{color: "#ffadad",}} icon={<FavoriteBorderIcon/>} checkedIcon={<FavoriteIcon/>} checked={cookies} onChange={handleChange} name="cookies" />}
                                    label="Custom Sugar Cookies - Coming Soon!"
                                />
                                </FormGroup>
                    </FormControl>

                </Grid>
                <Hidden mdDown>
                <Grid item lg={4} ></Grid>
                </Hidden>
                <Grid item xs={12} lg={4} >
                    <TextField
                    required
                    className = {classes.textFieldOutline}
                    variant='outlined'
                    label='Full Name'
                    name='name'
                    fullWidth
                    value={name}
                    onChange={e => setName(e.target.value)}
                    />
                </Grid>
                <Hidden mdDown>
                <Grid item lg={4} ></Grid>
                </Hidden>
                <Hidden mdDown>
                <Grid item lg={4} ></Grid>
                </Hidden>
                <Grid item xs={12} lg={4}>
                    <NumberFormat
                    customInput={TextField}
                    required
                    className = {classes.textFieldOutline}
                    variant='outlined'
                    fullWidth
                    value={number}
                    onChange={e => setNumber(e.target.value)}
                    label='Phone Number'
                    name='number'
                    format="+1 (###) ###-####" 
                    mask="_"
                    />
                </Grid>
                <Hidden mdDown>
                <Grid item lg={4} ></Grid>
                </Hidden>
                <Hidden mdDown>
                <Grid item lg={4} ></Grid>
                </Hidden>
                <Grid item xs={12} lg={4}>
                    <TextField
                    className = {classes.textFieldOutline}
                    variant='outlined'
                    label='Email'
                    name='email'
                    fullWidth
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    />
                </Grid>
                <Hidden mdDown>
                <Grid item lg={4} ></Grid>
                </Hidden>
                <Hidden mdDown>
                <Grid item lg={4} ></Grid>
                </Hidden>
                <Grid style={{display: value ? 'block' : 'none'}} item xs={12} lg={4}>
                    <TextField
                    className = {classes.textFieldOutline}
                    variant='outlined'
                    label='Address'
                    name='address'
                    fullWidth
                    value={address}
                    onChange={e => setAddress(e.target.value)}
                    />
                </Grid>
                <Hidden mdDown>
                <Grid item lg={4} style={{display: value ? 'block' : 'none'}} ></Grid>
                </Hidden>
                <Hidden mdDown>
                <Grid item lg={4} style={{display: value ? 'block' : 'none'}}></Grid>
                </Hidden>
                <Grid item xs={12} lg={4}>
                <Typography align='justify' className = {classes.typography}>Please list any specific themes, colors, letterings, quantity, or any other requests you have for your order. (If you have any picture inspiration, please use the button below) </Typography>
                <TextField
                    id="outlined-multiline-static"
                    label="Specific Requests"
                    className = {classes.textFieldOutline}
                    name='requests'
                    multiline
                    rows={4}
                    fullWidth
                    variant="outlined"
                    value={requests}
                    onChange={e => setRequests(e.target.value)}
                    />
                </Grid>
                <Hidden mdDown>
                <Grid item lg={4} ></Grid>
                </Hidden>
                <Grid item xs={12}>
                    <input
                        className={classes.input}
                        id="file"
                        name='file'
                        type="file"
                        onChange={handleUpload} 
                    />
                    <ThemeProvider theme={materialTheme}>
                    <label htmlFor="file">
                        <Button variant="contained" color="primary" component="span">
                        INSPO
                        </Button>
                    </label>
                    </ThemeProvider>
                    <br/>
                    {file && <CheckCircleIcon style={{ fontSize: 40, color: '#ffadad', paddingTop: '15px' }}/>}
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Typography align='justify' className = {classes.typography} >
                        Thank you for your interest in Sweetz by Fei! Please note that submitting this form does not guarantee your order.
                        I will contact you shortly after recieving this order request to discuss and confirm any specific details. 
                        Pickup orders are located either in El Sobrante or Davis, California, and delivery details will be discussed when I contact you. 
                        If you have any questions please reach out to me at, sweetzbyfei@gmail.com 
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                <ThemeProvider theme={materialTheme}>
                    <Button variant='contained' color='primary' type="submit" onClick={handleSubmit} component='span'>
                        Submit Order
                    </Button>
                </ThemeProvider>
                </Grid>
            </Grid>
        </form>
    )
}

export default Order

