import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        fontFamily: 'Spartan, sans-serif',
        minHeight: '100vh',

    },
    title: {
        fontSize: '4rem', 
        fontFamily: 'Spartan, sans-serif'  
    },
    menu: {
        fontSize: '1.25rem',
        fontWeight: 'bold'
    },
    prices: {
        fontSize: '1.25rem',
        paddingTop: '5px'
        
    },
    imageMarkedTitle: {
        height: 7,
        width: 60,
        backgroundColor: '#ffadad',
        position: 'absolute',
        left: 'calc(50% - 30px)'
      },
      imageMarkedMenu: {
        height: 5,
        width: 40,
        backgroundColor: '#ffadad',
        position: 'absolute',
        left: 'calc(50% - 20px)',
      },
  
});

function Pricing() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
        <Grid container spacing={4}>
        <Grid item xs={12}>
            <Typography className={classes.title}>Pricing</Typography>
            <span className={classes.imageMarkedTitle} />
        </Grid>
        <Grid item xs={12}>
            <Typography className={classes.menu}>chocolate covered strawberries</Typography>
            <span className={classes.imageMarkedMenu} />
            <Typography className={classes.prices}>half dozen - $15+</Typography>
            <Typography className={classes.prices}>full dozen - $30+</Typography>
            <Typography className={classes.prices}>berry bouquet - $45+</Typography>
            
            <Typography> </Typography>
        </Grid>
        <Grid item xs={12}>
            <Typography className={classes.menu}>breakable heart</Typography>
            <span className={classes.imageMarkedMenu} />
            <Typography className={classes.prices}>heart - $35+</Typography>
            <Typography className={classes.prices}>heart + 6 berries - $45+</Typography>
        </Grid>
        <Grid item xs={12}>
            <Typography className={classes.menu}>churro cheesecake</Typography>
            <span className={classes.imageMarkedMenu} />
            <Typography className={classes.prices}>per slice - $4</Typography>
            <Typography className={classes.prices}>pan (12 slices) - $45+</Typography>
            <Typography className={classes.prices}>nutella/condensed milk drizzle - $1 per slice</Typography>
        </Grid>
        <Grid item xs={12}>
            <Typography className={classes.menu}>custom sugar cookies</Typography>
            <span className={classes.imageMarkedMenu} />
            <Typography className={classes.prices}>coming soon!</Typography>
        </Grid>
        </Grid>
        </div>
    )
}

export default Pricing
