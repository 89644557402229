const sweets = [
    {
      title: 'Chocolate Covered Strawberries ',
      description:
        "Custom dipped strawberries in premium dark, milk, or white compound chocolate customized for any occasion. ",
      imageUrl: process.env.PUBLIC_URL + '/assets/chocoberry.jpeg',
      time: 1500,
    },
    {
      title: 'Breakable Heart',
      description:
        'Small items are placed underneath the heart for a unique way to gift items to your loved ones.',
      imageUrl: process.env.PUBLIC_URL + '/assets/breakableheart.jpeg',
      time: 1500,
    },
    {
      title: 'Churro Cheesecake',
      description:
        'Sprinkled with layers of cinnamon sugar and topped with sliced strawberries and one choice of drizzle - either nutella or condensed milk. ',
      imageUrl: process.env.PUBLIC_URL + '/assets/churrocheesecake.jpeg',
      time: 1500,
    },
    {
      title: 'Custom Sugar Cookies',
      description:
      'A soft bite vanilla bean sugar cookie frosted with a sweet layer of royal icing, hand decorated for any occasion. One dozen minimum.',
      imageUrl: process.env.PUBLIC_URL + '/assets/comingsoon.png',
      time: 1500,
    },
    {
      title: 'About Me',
      description:
      'Hi, I’m Fei! I’m a college student attending the University of California, Davis and I started this journey in September of 2020 as just a quarantine hobby during the COVID-19 pandemic.  ',
      imageUrl: process.env.PUBLIC_URL + '/assets/island2.jpg',
      time: 1500,
    },
  ];
  
  export default sweets;