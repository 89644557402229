import './App.css';
import Main from '../src/components/Main/Main'
import Header from '../src/components/Header/Header'
import Footer from '../src/components/Footer/Footer'

function App() {
  return (
    <div className="App">
      <Header />
      <Main />
      <Footer/>
    </div>
  );
}

export default App;
