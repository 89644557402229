import * as React from 'react'
import { IconButton, Drawer, List, ListItem, ListItemText } from '@material-ui/core'
import { Menu } from '@material-ui/icons'
import { makeStyles } from "@material-ui/core/styles";
import { useState } from 'react'
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';

const useStyles = makeStyles({
    list: {
      width: 250,
    },
    linkText: {
      textDecoration: `none`,
      textTransform: `lowercase`,
      color: `black`,
      justifyContent: 'center',
    },
    text: {
        textAlign: 'left',
    },
    drawerPaper: {
        backgroundColor: "#F5FFFB",
        paddingTop: 20
    },
    socials: {
        textAlign: 'center'
    }
  });

const SideDrawer = () => {
    const classes = useStyles();
    const [state, setState] = useState({ right: false})

    const toggleDrawer = (anchor, open) => event => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
    )   {
        return
    }
        setState({ [anchor]: open })
    };

    const sideDrawerList = anchor => (
        <div
          className={classes.list}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
            <List component ='nav'>
                <a href='/' key='Home' className={classes.linkText}>
                    <ListItem button>
                        <ListItemText primary='home' className={classes.text}/>
                    </ListItem>
                </a>
                <a href='/pricing' key='Pricing' className={classes.linkText}>
                    <ListItem button>
                        <ListItemText primary='pricing' className={classes.text}/>
                    </ListItem>
                </a>
                <a href='/order' key='Order' className={classes.linkText}>
                    <ListItem button>
                            <ListItemText primary='order' className={classes.text}/>
                        </ListItem>
                </a>
                <a href='/gallery' key='Gallery' className={classes.linkText}>
                    <ListItem button>
                        <ListItemText primary='gallery' className={classes.text}/>
                    </ListItem> 
                </a>
                <a href='/FAQ' key='FAQ' className={classes.linkText}>
                    <ListItem button>
                        <ListItemText primary='faq' className={classes.text} />
                    </ListItem> 
                </a>
            </List>
            <div className={classes.socials}>
            <a className='fb' href='https://www.facebook.com/fei.sheehan'> <FacebookIcon style={{ fontSize: 40, color: '#ffadad' }} /> </a>
            <a className='ig' href='https://www.instagram.com/sweetzbyfei/'> <InstagramIcon style={{ fontSize: 40, color: '#ffadad' }} /> </a>
            </div>
        </div>
    );

    return (
        <React.Fragment>
            <IconButton edge='start' aria-label='menu' onClick={toggleDrawer('right', true)}>
                <Menu fontSize='large' style={{color: '#ffadad'}}/>
            </IconButton>
            <Drawer  classes={{paper: classes.drawerPaper}} anchor='right' open={state.right} onOpen={toggleDrawer('right', true)} onClose={toggleDrawer('right',false)}>
                {sideDrawerList('right')}
            </Drawer>
        </React.Fragment>
    );
};

export default SideDrawer