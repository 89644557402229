import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {  IconButton, Collapse } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link as Scroll } from 'react-scroll';
import hero from '../../assets/avocado.jpeg';


const useStyles = makeStyles({
  root: {
    
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100vh',
    alignItems: 'center',
    fontFamily: 'Spartan',
    backgroundColor: '#F5FFFB',
  },
  icon: {
    color: '#fff',
    fontSize: '2rem',
  },
  colorText: {
    color: '#ffadad',
  },
  container: {
    textAlign: 'center',
  },
  title: {
    color: 'black',
    fontSize: '3rem', 
  },
  goDown: {
    color: '#ffadad',
    fontSize: '3rem',
  },
});

function Hero() {
    const classes = useStyles();
    const [checked, setChecked] = useState(false);
    useEffect(() => {
      setChecked(true);
    }, []);

    return (
      <div className={classes.root} id="header">
       <div className='heroDiv'> 
       <img className='heroImg' src={hero} alt='Hero'/>
       </div>
       
         
      <Collapse
        in={checked}
        {...(checked ? { timeout: 1000 } : {})}
        collapsedHeight={50}
      >
        <div className={classes.container}>
          <h1 className={classes.title}>
          <span className={classes.colorText}> Sweets </span> <br />
            For Every Occasion.
          </h1>
          <Scroll to="place-to-visit" smooth={true}>
            <IconButton>
              <ExpandMoreIcon className={classes.goDown} />
            </IconButton>
          </Scroll>
        </div>
      </Collapse>
    </div>
  );
  }
  
  export default Hero;