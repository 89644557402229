import React from 'react'
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import { Typography } from '@material-ui/core';

function Footer() {
    return (
        <div className='footer'>
            <a className='fb' href='https://www.facebook.com/fei.sheehan'> <FacebookIcon style={{ fontSize: 40, color: '#ffadad' }} /> </a>
            <a className='ig' href='https://www.instagram.com/sweetzbyfei/'> <InstagramIcon style={{ fontSize: 40, color: '#ffadad' }} /> </a>
            <Typography style={{fontFamily: 'Spartan'}}>sweetzbyfei@gmail.com</Typography>
        </div>
    )
}

export default Footer
