import React from 'react'
import {Typography, Grid, Button} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { Link } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';


const useStyles = makeStyles({
    root: {
        minHeight: '80vh',
    },
    text: {
        textAlign: 'center',
        fontSize: '1.15rem',
        padding: '10px',
        fontFamily: 'Spartan'
    },
    link: {
        textDecoration: 'none'
    }
});

const materialTheme = createMuiTheme({
    palette: {
        primary: { 500: '#ffadad' },
      },
  });
function Thankyou() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
        <Grid container spacing={3} >
            <Grid item xs={3}></Grid>
            <Grid item xs={12} lg={6}>
           <Typography className={classes.text}> Thank you for your inquiry and interest in SweetzbyFei! 
           You should expect a response from me within 24 hours to discuss the details of 
           your order and confirm any final prices. I look forward to bringing your visions
            to life! </Typography> 
            <ThemeProvider theme={materialTheme}>
                <Link className={classes.link} to='/'>
                    <Button size='large' variant='contained' color='primary' type="submit" component='span'>
                        <HomeIcon/>
                    </Button>
                </Link>
                </ThemeProvider>
            </Grid>
            <Grid item xs={3}></Grid>
        </Grid>
        </div>
    )
}

export default Thankyou
