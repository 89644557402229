  
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Collapse } from '@material-ui/core';
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { Link } from 'react-router-dom';

const materialTheme = createMuiTheme({
    palette: {
        primary: { 500: '#ece3dd' },
      },
});

const useStyles = makeStyles({
  root: {
    maxWidth: 645,
    background: '#ffadad',
    margin: '20px',
    marginTop: '0px',
  },
  media: {
    height: 440,
  },
  title: {
    fontFamily: 'Spartan',
    fontWeight: 'bold',
    fontSize: '2rem',
    color: '#fff',
  },
  desc: {
    fontFamily: 'Spartan',
    fontSize: '1.1rem',
    color: '#fff',
  },
  action: {
    display: 'flex',
    justifyContent: 'center'
  },
  orderBtn: {
      marginBottom: '15px',
      fontFamily: 'Spartan',
      fontWeight: 'bold'
  },
  link: {
      textDecorationColor: 'black'
  }
});

export default function ImageCard({ sweet, checked }) {
  const classes = useStyles();

  return (
    <Collapse in={checked} {...(checked ? { timeout: 1000 } : {})}>
        <Card className={classes.root}>
            <CardMedia
            className={classes.media}
            image={sweet.imageUrl}
            title="Sweets"
            />
            <CardContent>
                <Typography
                gutterBottom
                variant="h5"
                component="h1"
                className={classes.title}
                >
                    {sweet.title}
                </Typography>
            <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.desc}
            >
                {sweet.description}
            </Typography>
            </CardContent>
            <ThemeProvider theme={materialTheme}>
            <CardActions className={classes.action}>
            <Link className={classes.link} to='/order'>
            <Button className={classes.orderBtn} variant='contained' color='primary' component='span'>
                Order Now
            </Button>
            </Link>
            </CardActions>
            </ThemeProvider>
        </Card>
    </Collapse>
  );
}